import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

import 'vue-select/dist/vue-select.css';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueGtag, {
    config: { id: "G-QRDPEJXY4D" }
})
app.mount('#app')

app.directive('loading', (el, binding) => {
    if (binding.value) {
        el.classList.add("loading");
    } else {
        el.classList.remove("loading")
    }
})

app.config.globalProperties.$filter = {
    phoneClear(value) {
        let newPhone = value.replace(/^(9|\+9|90|\+90|0)|[^0-9]/g, "");

        let num1 = newPhone.substr(0, 3) || '';
        let num2 = newPhone.substr(3, 3) || '';
        let num3 = newPhone.substr(6, 4) || '';

        let format = '';
        if (num1.length) {
            format = '(';
            format += num1;

            if (newPhone.length > 3) {
                format += ')';
            }

            if (num2.length) {
                format += ' ';
                format += num2;
            }

            if (num3) {
                format += '-';
                format += num3;
            }
        }

        return format;
    }
}
