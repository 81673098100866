<script setup>
import BaseHeader from '@/components/template/BaseHeader.vue';
import BaseFooter from '@/components/template/BaseFooter.vue';
import NotificationError from './components/template/NotificationError.vue';
import { onMounted } from 'vue';
import { event } from 'vue-gtag';
import { useRoute } from 'vue-router';

const route = useRoute()

onMounted(() => {
  event('page_view', {
    page_title: route.meta.title,
    page_location: window.location.href
  })
})
</script>

<template>
  <BaseHeader />
  <NotificationError />

  <div class="container">
    <RouterView />
  </div>

  <BaseFooter />
</template>

<style lang="scss"></style>
